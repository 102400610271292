.fontello-mail:before { content: '\e800'; } /* '' */
.fontello-call:before { content: '\e801'; } /* '' */
.fontello-star:before { content: '\e802'; } /* '' */
.fontello-down-open:before { content: '\e803'; } /* '' */
.fontello-left-open:before { content: '\e804'; } /* '' */
.fontello-location-outline:before { content: '\e805'; } /* '' */
.fontello-right-open:before { content: '\e806'; } /* '' */
.fontello-up-open:before { content: '\e807'; } /* '' */
.fontello-mail-1:before { content: '\e808'; } /* '' */
.fontello-location:before { content: '\e809'; } /* '' */
.fontello-star-half:before { content: '\e80a'; } /* '' */
.fontello-minus:before { content: '\e80c'; } /* '' */
.fontello-ok:before { content: '\e80d'; } /* '' */
.fontello-phone:before { content: '\e80e'; } /* '' */
.fontello-cancel:before { content: '\e80f'; } /* '' */
.fontello-twitter:before { content: '\f099'; } /* '' */
.fontello-facebook:before { content: '\f09a'; } /* '' */
.fontello-gplus-squared:before { content: '\f0d4'; } /* '' */
.fontello-gplus:before { content: '\f0d5'; } /* '' */
.fontello-linkedin:before { content: '\f0e1'; } /* '' */
.fontello-angle-double-left:before { content: '\f100'; } /* '' */
.fontello-angle-double-right:before { content: '\f101'; } /* '' */
.fontello-angle-double-up:before { content: '\f102'; } /* '' */
.fontello-angle-double-down:before { content: '\f103'; } /* '' */
.fontello-angle-left:before { content: '\f104'; } /* '' */
.fontello-angle-right:before { content: '\f105'; } /* '' */
.fontello-angle-up:before { content: '\f106'; } /* '' */
.fontello-angle-down:before { content: '\f107'; } /* '' */
.fontello-youtube-squared:before { content: '\f166'; } /* '' */
.fontello-youtube:before { content: '\f167'; } /* '' */
.fontello-youtube-play:before { content: '\f16a'; } /* '' */
.fontello-instagram:before { content: '\f16d'; } /* '' */
.fontello-down:before { content: '\f175'; } /* '' */
.fontello-up:before { content: '\f176'; } /* '' */
.fontello-left:before { content: '\f177'; } /* '' */
.fontello-right:before { content: '\f178'; } /* '' */
.fontello-twitter-squared:before { content: '\f304'; } /* '' */
.fontello-facebook-squared:before { content: '\f308'; } /* '' */
.fontello-linkedin-squared:before { content: '\f30c'; } /* '' */